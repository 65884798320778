import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { SostituzionePasswordComponent } from './pages/sostituzione-password/sostituzione-password.component';
import { AppRoutingModule } from './app-routing.module';
import { DefaultPageComponent } from './layout/default-page/default-page.component';
import { FooterComponent } from './layout/default-page/footer/footer.component';
import { NavbarComponent } from './layout/default-page/navbar/navbar.component';
import { HomeComponent } from './pages/home/home.component';
import { PaginaConfermaEmailComponent } from './pages/pagina-conferma-email/pagina-conferma-email.component';

@NgModule({
  declarations: [
    AppComponent,
    SostituzionePasswordComponent,
    DefaultPageComponent,
    FooterComponent,
    NavbarComponent,
    HomeComponent,
    PaginaConfermaEmailComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
