<div class="navbar navbar-custom navbar-expand-lg bg-danger">

    <div class="nav-mobile">
        <div class="logo animate__animated animate__fadeIn">
            <a href="https://www.lineaerotica24.net/"> <img src="/assets/logo-fix.png" class="img-fluid custom-logo"
                    alt="chat monella"></a>
        </div>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
            aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fa fa-bars" aria-hidden="true"></i>
        </button>
    </div>

    <div class="collapse navbar-collapse" id="navbarCollapse">
        <div class="menu-links">

            <ul class="my-auto nav-links">
                <li>
                    <a class="nav-item animate__animated animate__fadeIn" href="#"><i class="fa fa-info"></i>
                        COME SCARICARLA</a>
                </li>

                <li>
                    <a class="nav-item animate__animated animate__fadeIn" href="#"><i class="fa fa-info-circle"></i>
                        COME FUNZIONA?</a>
                </li>

                <li>
                    <a class="nav-item mobile-item animate__animated animate__fadeIn"
                        href="https://app.chatmonella.it/registrazione"><i class="fa fa-globe"></i>
                        UTILIZZA DAL WEB</a>
                </li>

                <li>
                    <a class="nav-item animate__animated animate__fadeIn"
                        href="https://app.chatmonella.it/registrazione"><i class="fa fa-download"></i>
                        SCARICA GRATIS</a>
                </li>

                <li>
                    <a class="nav-item animate__animated animate__fadeIn" href="#password"> PASSWORD DIMENTICATA</a>
                </li>
            </ul>

        </div>
    </div>

</div>